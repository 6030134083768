@import "styles/variables.scss";

.header {
  position: sticky;
  z-index: 2;
  top: 0;
  height: 90px;
  background-color: $black;
  border-bottom: 1px solid transparentize($primary-color, 0.6);
}
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.logo-container {
  display: flex;
  align-items: center;
}
.logo {
  width: 12.5rem;
}
.navigation-menu {
  height: 100%;
  display: none;
  align-items: center;
  @media (min-width: 768px) {
    display: flex;
  }
  &:hover {
    &:before {
      display: block;
    }
  }
  &.expanded {
    display: block;
    position: fixed;
    top: 91px;
    left: 0;
    right: 0;
    z-index: 2;
    height: calc(100% - 90px);
    background-color: $black;
    overflow-y: hidden;
    padding: 3rem 0;
    @media (min-width: 768px) {
      display: none;
    }
    .navigation-link {
      height: auto;
      font-size: 1.5rem;
      justify-content: center;
      padding: 0;
      margin: 3rem 0;
      &:before {
        display: none;
      }
      &:hover {
        color: $primary-color;
      }
    }
    :global(.active) {
      color: $primary-color;
    }
    .cta {
      font-size: 1.3rem;
      width: calc(100% - 2rem);
      margin: 1rem 1rem 0;
      padding: 0.875rem 1rem;
    }
  }
}
.navigation-link {
  font-size: 1.125rem;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  color: $white;
  font-weight: 500;
  text-decoration: none;
  padding: 0 2rem;
  letter-spacing: 0.5px;
  transition: all 0.3s;
  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 0;
    background-color: $primary-color;
    transition: height 0.3s;
  }
  &:hover {
    &:before {
      height: 0.3125rem;
      transition: height 0.3s;
    }
  }
}
:global(.active:before) {
  height: 0.3125rem;
}
.cta {
  font-size: 1rem;
  padding: 0.625rem 1rem;
  margin-left: 1rem;
}
.menuButton {
  border: none;
  background: transparent;
  @media (min-width: 768px) {
    display: none;
  }
}
.menuIcon {
  width: 1.875rem;
  height: 1.875rem;
}
