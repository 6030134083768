@import "styles/variables.scss";

.page {
  padding: 6.25rem 0;
  background-color: $black;
  color: $white;
}
.container {
  min-height: calc(100vh - 25rem);
  @media (min-width: 1200px) {
    max-width: 50rem;
  }
}
