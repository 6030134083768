.section-features {
  max-width: 68.75rem;
  padding: 5rem 0;
  margin: auto;
  @media (min-width: 660px) {
    padding: 7.82rem 0;
  }
}
.feature-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 2rem;
  @media (min-width: 660px) {
    margin-top: 8.75rem;
  }
}
.feature-block {
  margin: 4rem 0;
  @media (min-width: 660px) {
    width: 45%;
  }
}
.feature-image {
  display: block;
  margin: 0 auto 1.5rem;
}
.feature-title {
  font-size: 1.125rem;
  text-align: center;
  margin-bottom: 1.25rem;
}
.container {
  @media (min-width: 660px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}
.intro-container {
  @media (min-width: 660px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.text-container {
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 3rem;
  @media (min-width: 660px) {
    width: 40%;
    margin-bottom: 0;
  }
}
.image-container {
  display: block;
  height: 30rem;
  overflow: hidden;
  border-top-left-radius: 9.375rem;
  background-image: url("/assets/images/features-background.jpg");
  background-size: cover;
  background-position: center;
  @media (min-width: 660px) {
    width: 55%;
  }
}
.title {
  font-weight: 800;
  margin-bottom: 1.875rem;
}
.feature-list-item {
  line-height: 1.5;
  margin-bottom: 1rem;
}
