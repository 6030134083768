@import "styles/variables.scss";

.hero {
  position: relative;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  color: $white;
  padding: 8rem 0;
  @media (min-width: 768px) {
    height: calc(100vh - 5.625rem);
    max-height: 45rem;
  }
}
.hero:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  background-color: $black;
}
.hero-loaded {
  @extend .hero;
  background-image: url("/assets/images/hero-background.jpg");
  &:before {
    background-color: rgba(3, 11, 21, 0.8);
    transition: all 1s ease-in-out;
  }
}
.hero-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  text-align: center;
  letter-spacing: 0.5px;
  @media (min-width: 600px) {
    max-width: 70%;
  }
  @media (min-width: 1200px) {
    max-width: 50%;
  }
  @media all and (max-width: 1024px) and (max-height: 768px) and (orientation: landscape) {
    text-align: left;
    max-width: 100%;
  }
  .hero-title,
  .hero-subtitle {
    opacity: 0;
  }
}
.hero-container-loaded {
  @extend .hero-container;
  .hero-title,
  .hero-subtitle {
    opacity: 1;
    transform: translateY(-5px);
    transition: 1s;
  }
}
.blue-text {
  color: $primary-color;
}
.hero-title {
  margin-bottom: 2.25rem;
}
.hero-subtitle {
  font-size: 1.5rem;
  line-height: 1.6;
  margin: 0 auto 3.5rem;
  @media (min-width: 768px) {
    max-width: 80%;
  }
  @media all and (max-width: 1024px) and (max-height: 768px) and (orientation: landscape) {
    margin: 0;
  }
}
