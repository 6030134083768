@import "styles/variables.scss";

.section-about {
  padding: 6.25rem 0;
  background-color: $black;
  color: $white;
}
.container {
  max-width: 50rem;
}
.subheading {
  text-align: center;
}
