@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;800;900&display=swap");

body,
* {
  line-height: 1.8;
  margin: 0;
  font-family: "Raleway", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1 {
  font-size: 2rem;
  line-height: 1.5;
  margin-bottom: 4.375rem;
  @media (min-width: 660px) {
    font-size: 2.5rem;
  }
}
h2 {
  font-size: 1.875rem;
  margin-bottom: 4.375rem;
}
h3 {
  font-size: 1.75rem;
  line-height: 1.5;
  margin-bottom: 3.5rem;
}
h4 {
  font-size: 1.5rem;
  margin-bottom: 2.25rem;
}
p {
  margin-bottom: 1rem;
}
