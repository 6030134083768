@import "styles/variables.scss";

.section-team {
  color: $white;
  background-color: $black;
  padding: 6.25rem 0;
}
.section-content {
  max-width: 50rem;
  margin: auto;
}
.section-team .container {
  max-width: 68.75rem;
}
.team-items {
  margin: 5rem 0 3rem;
  @media (min-width: 660px) {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8rem;
  }
}
.team-item {
  margin: 8rem 0;
  @media (min-width: 660px) {
    margin: 2.5rem 2rem 3.75rem;
    width: calc(50% - 4rem);
  }
  @media (min-width: 1024px) {
    margin: 2.5rem 4rem 3.75rem;
    width: calc(50% - 8rem);
  }
  &:last-of-type {
    @media (max-width: 1023px) {
      margin-bottom: 0;
    }
  }
}
.team-item-img-container {
  margin: auto;
  position: relative;
  display: block;
  width: 10rem;
  height: 10rem;
  text-align: center;
}
.team-item-img {
  background-size: cover;
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
  transition: filter 0.4s;
}
.team-item-name {
  margin-bottom: 0;
  text-align: center;
}
.team-item-description {
  font-size: 0.875rem;
}
.linkedin-icon {
  position: absolute;
  bottom: 0.625rem;
  right: 0;
  width: 1.625rem;
  height: 1.625rem;
  background-image: url("/assets/icons/linked-icon.svg");
  background-size: cover;
  transition: all 0.4s;
}
.team-item-img-container {
  &:hover {
    .linkedin-icon {
      filter: brightness(0.6);
      transition: filter 0.4s;
    }
  }
}
.team-item-name {
  font-size: 1.375rem;
  margin-top: 1.25rem;
}
.team-item-position {
  color: $primary-color;
  font-weight: 500;
  margin-bottom: 1.5rem;
  text-align: center;
}
.heading {
  text-align: center;
}
