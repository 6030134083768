@import "styles/variables.scss";

.timeline {
  max-width: 50rem;
  margin: 6rem auto;
}
.timeline-controls {
  background-color: transparent !important;
  position: absolute;
  bottom: -6.875rem;
  width: 100%;
  max-width: 50rem;
  justify-content: space-between;
}
.timeline-controls li {
  display: none;
}
.timeline-controls li:nth-of-type(2),
.timeline-controls li:nth-of-type(3) {
  display: block;
}
:global(.timeline-horizontal-container li) {
  height: 8.125rem;
}
:global(
    .timeline-horz-item-container:nth-of-type(1)
      .timeline-circle:not(.using-icon).active
  ),
:global(
    .timeline-horz-item-container:nth-of-type(5n)
      .timeline-circle:not(.using-icon).active
  ),
:global(.timeline-vertical-circle > div:not(.using-icon).active),
:global(
    .vertical-item-row:nth-of-type(5n) > div:not(.using-icon).active:before
  ) {
  outline-color: rgba($primary-color, 0.4);
  background: #2cbca5;
  border: none;
}
:global(
    .timeline-horz-item-container:nth-of-type(2)
      .timeline-circle:not(.using-icon).active
  ),
:global(
    .timeline-horz-item-container:nth-of-type(6n)
      .timeline-circle:not(.using-icon).active
  ),
:global(
    .vertical-item-row:nth-of-type(2)
      .timeline-vertical-circle
      > div:not(.using-icon).active
  ),
:global(
    .vertical-item-row:nth-of-type(6n)
      .timeline-vertical-circle
      > div:not(.using-icon).active
  ) {
  outline-color: rgba(255, 151, 0, 0.4);
  background: #ff9700;
}
:global(
    .timeline-horz-item-container:nth-of-type(3)
      .timeline-circle:not(.using-icon).active
  ),
:global(
    .timeline-horz-item-container:nth-of-type(7n)
      .timeline-circle:not(.using-icon).active
  ),
:global(
    .vertical-item-row:nth-of-type(3)
      .timeline-vertical-circle
      > div:not(.using-icon).active
  ),
:global(
    .vertical-item-row:nth-of-type(7n)
      .timeline-vertical-circle
      > div:not(.using-icon).active
  ) {
  outline-color: transparentize($primary-color, 0.6);
  background: $primary-color;
}
:global(
    .timeline-horz-item-container:nth-of-type(4)
      .timeline-circle:not(.using-icon).active
  ),
:global(
    .timeline-horz-item-container:nth-of-type(8n)
      .timeline-circle:not(.using-icon).active
  ),
:global(
    .vertical-item-row:nth-of-type(4)
      .timeline-vertical-circle
      > div:not(.using-icon).active
  ),
:global(
    .vertical-item-row:nth-of-type(8n)
      .timeline-vertical-circle
      > div:not(.using-icon).active
  ) {
  outline-color: rgba(255, 69, 129, 0.4);
  background: #ff4581;
}
:global(.timeline-circle),
:global(.timeline-vertical-circle > div:not(.using-icon)) {
  width: 1rem;
  height: 1rem;
  outline: 0.45rem solid rgba(#26515e, 0.4);
  @media not all and (min-resolution: 0.001dpcm) {
    outline: none;
    position: relative;
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 2.25rem;
      height: 2.25rem !important;
      top: -0.625rem;
      left: -0.625rem;
      background: transparentize(#26515e, 0.6);
      border-radius: 50%;
    }
  }
}
:global(.timeline-circle.horizontal:not(.using-icon)),
:global(.timeline-vertical-circle > div:not(.using-icon)) {
  background: #26515e;
}
:global(.timeline-horz-item-container .timeline-item-title.active) {
  background: none;
  color: #2cbca5;
}
:global(.timeline-circle.horizontal:not(.using-icon).active) {
  border: none;
}
:global(.timeline-item-title.active.rc-title) {
  background: transparent;
}
:global(.timeline-horz-item-container:nth-of-type(1) .timeline-item-title),
:global(.timeline-horz-item-container:nth-of-type(5n) .timeline-item-title),
:global(.vertical-item-row:nth-of-type(1) .timeline-item-title.rc-title),
:global(.vertical-item-row:nth-of-type(5n) .timeline-item-title.rc-title) {
  color: #2cbca5;
}
:global(.timeline-horz-item-container:nth-of-type(2) .timeline-item-title),
:global(.timeline-horz-item-container:nth-of-type(6n) .timeline-item-title),
:global(.vertical-item-row:nth-of-type(2) .timeline-item-title.rc-title),
:global(.vertical-item-row:nth-of-type(6n) .timeline-item-title.rc-title) {
  color: #ff9700;
}
:global(.timeline-horz-item-container:nth-of-type(3) .timeline-item-title),
:global(.timeline-horz-item-container:nth-of-type(7n) .timeline-item-title),
:global(.vertical-item-row:nth-of-type(3) .timeline-item-title.rc-title),
:global(.vertical-item-row:nth-of-type(7n) .timeline-item-title.rc-title) {
  color: $primary-color;
}
:global(.timeline-horz-item-container:nth-of-type(4) .timeline-item-title),
:global(.timeline-horz-item-container:nth-of-type(8n) .timeline-item-title),
:global(.vertical-item-row:nth-of-type(4) .timeline-item-title.rc-title),
:global(.vertical-item-row:nth-of-type(8n) .timeline-item-title.rc-title) {
  color: #ff4581;
}
.card-text {
  margin: 1rem 0;
  width: 100% !important;
  padding: 0;
}
.card-text p {
  font-size: 1rem;
  line-height: 1.625;
  color: $white;
  margin: 0;
}
.card-text:after {
  display: none;
}
:global(.timeline-horz-card-wrapper .timeline-card-content.active),
:global(.vertical .timeline-item-title.active.rc-title) {
  background: transparent;
  @media (min-width: 1024px) {
    width: 70%;
    margin: auto;
  }
}
:global(.vertical-item-row .timeline-item-title) {
  font-size: 1.3rem;
  padding: 0;
  padding-right: 1rem;
}
:global(.timeline-card-content header) {
  padding: 0;
}
:global(.timeline-card-content h1) {
  font-size: 1.25rem;
  font-weight: 700;
  color: #fff;
}
.timeline-controls button {
  width: 4rem;
  height: 4rem;
  color: #26515e;
  background: none;
  border: none;
  &:hover {
    background: transparentize(#023040, 0.4);
    transition: all 0.3s;
  }
  &:active {
    transform: none;
  }
}
.timeline-controls button[aria-disabled="true"] {
  display: none;
}
:global(.timeline-card-content) {
  background: transparent !important;
  margin: auto;
  @media (max-width: 767px) {
    margin-left: 1rem;
  }
}
:global(.timeline-card-content span[dir="right"]) {
  display: none;
}
:global(.timeline-main-wrapper div[role="list"]) {
  padding: 0;
}
:global(.card-content-wrapper.right) {
  width: 70%;
}

.timeline-mode-vertical {
  display: block;
}
.timeline-mode-horizontal {
  display: none;
}
@media (orientation: landscape), (min-width: 1024px) {
  .timeline-mode-vertical {
    display: none;
  }

  .timeline-mode-horizontal {
    display: block;
  }
}
