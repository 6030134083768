.section-news {
  padding: 6.25rem 0 5rem;
  @media (min-width: 660px) {
    padding: 8.75rem 0;
  }
}
.container {
  @media (min-width: 660px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.text-container {
  font-size: 1.125rem;
  font-weight: 600;
  margin-top: 3rem;
  @media (min-width: 660px) {
    width: 40%;
    margin-top: 0;
  }
}
.image-container {
  display: block;
  height: 30rem;
  border-top-right-radius: 9.375rem;
  overflow: hidden;
  width: 100%;
  background-image: url("/assets/images/news-background.jpg");
  background-size: cover;
  background-position: center;
  @media (min-width: 660px) {
    width: 55%;
  }
}

.heading {
  font-weight: 800;
  margin-bottom: 1.875rem;
}
