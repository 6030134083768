@import "styles/variables.scss";

.link {
  position: relative;
  color: inherit;
  text-decoration: none;
  display: inline-block;
  line-height: 1.5;
  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $primary-color;
    transition: height 0.3s;
  }
  &:hover {
    cursor: pointer;
    &:before {
      height: 0.18rem;
      transition: height 0.3s;
    }
  }
}
