@import "styles/variables.scss";

.footer {
  background-color: $black;
  color: $white;
  border-top: 1px solid rgba(18, 59, 72, 0.75);
}
.footer-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding: 0;
}
.footer-list-item {
  display: flex;
  margin: 1.25rem 0 3rem;
  @media (min-width: 600px) {
    margin: 1.25rem 0;
  }
}
.footer-link-icon {
  width: 1.875rem;
  height: 1.875rem;
  display: block;
  background-repeat: no-repeat;
  margin: 0 0.3125rem;
  transition: filter 0.4s;
}
.footer-link-icon:hover {
  filter: brightness(0.6);
  transition: filter 0.4s;
}
.twitter {
  background-image: url("/assets/icons/twitter-icon.svg");
}
.linkedin {
  background-image: url("/assets/icons/linked-icon.svg");
}
.footer-link {
  margin: 0 0.625rem;
}
.copyright {
  margin: 0;
  padding-bottom: 1.25rem;
  text-align: center;
}
