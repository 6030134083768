.section-container {
  max-width: 75rem;
  height: 100%;
  margin: auto;
  position: relative;
  padding: 0 1rem;
  @media (min-width: 660px) {
    padding: 0 2rem;
  }
}
