@import "styles/variables.scss";

.page-title-container {
  display: flex;
  justify-content: center;
}
.page-title {
  font-size: 1.875rem;
  line-height: 1.2;
  letter-spacing: 1.5px;
  color: $white;
  text-transform: uppercase;
  position: relative;
  margin-left: 1.875rem;
}
.page-title:before {
  content: "";
  display: block;
  position: absolute;
  left: -3.125rem;
  top: 0;
  width: 2.3rem;
  height: 2.3rem;
  background-image: url("/assets/icons/circle.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
}
